import ToggleMenu from './components/toggle-menu';
import SwipeCards from './components/swipe-cards';
import ChangeStatus from './components/status';
import SplashScreen from './components/splash-screen';
import Warning from './components/warning';
import OnloadAnimation from './components/onload-animation';
import FoodMenu from './components/food-menu';
import JanitorMessage from './components/janitor-message';
import Activities from './components/activities';
import Messages from './components/messages';
import LoginForm from './components/login-form';
import FormValidation from './components/form-validation';
import Upload from './components/upload';
import RegisterServiceWorker from "./components/register-service-worker";

const swipeCards = new SwipeCards(jQuery('.js-swipe-cards'), jQuery);
const toggleMenu = new ToggleMenu(jQuery('.js-toggle-button'), jQuery);
const status = new ChangeStatus(jQuery('.js-change-status'), jQuery);
const splashScreen = new SplashScreen(jQuery('.js-splash-screen'), jQuery);
const warning = new Warning(jQuery('.js-warning-container'), jQuery);
const onloadAnimation = new OnloadAnimation(jQuery('.js-onload-animation'), jQuery);
const foodMenu = new FoodMenu(jQuery('.js-food-menu'), jQuery);
const janitorMessage = new JanitorMessage(jQuery('.js-janitor-message'), jQuery);
const activities = new Activities(jQuery('.js-activities'), jQuery);
const messages = new Messages(jQuery('.js-messages'), jQuery);
const loginForm = new LoginForm(jQuery('.js-login-form'), jQuery);
const formValidation = new FormValidation(jQuery('.js-form-validation'), jQuery);
const upload = new Upload(jQuery('.js-upload'), jQuery);
const registerServiceWorker = new RegisterServiceWorker(jQuery('.js-register-service-worker'), jQuery);

swipeCards.init();
toggleMenu.init();
status.init();
splashScreen.init();
warning.init();
onloadAnimation.init();
foodMenu.init();
janitorMessage.init();
activities.init();
messages.init();
loginForm.init();
formValidation.init();
upload.init();
registerServiceWorker.init();

