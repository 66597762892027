class SwipeCards {
    constructor(swipe_container, $) {
        this.swipe_container = swipe_container;
        this.swipe_submenu = $('.js-pagination');
        this.swipe_submenu_width = this.swipe_submenu.width();
        this.submenu_elements = this.swipe_submenu.find('.js-page');
        this.swipe_cards_container = $('.js-slider');
        this.swipe_cards = this.swipe_cards_container.find('.js-slider-item');
        this.swipe_arrow_prev = this.swipe_container.find('.js-swipe-cards__arrow-container--prev');
        this.swipe_arrow_next = this.swipe_container.find('.js-swipe-cards__arrow-container--next');
        this.state = {
            current_card: null
        };

    }

    _initHammer() {
        new Hammer(this.swipe_cards_container[0], {
            domEvents: true
        });
    }

    _getInitialCard() {
        const id = this.swipe_submenu.find('.submenu__li--active').attr('data-id');
        this._changeCard(id);
        this._scrollMenu();
        this.state.current_card = parseInt(id);
    }

    _changeSubmenu(id) {
        if (id != this.state.current_card) {
            this.submenu_elements.removeClass('submenu__li--active');
            const submenu = this.swipe_submenu.find('.js-page[data-id="' + id + '"]');
            submenu.addClass('submenu__li--active');
            this._scrollMenu();
        }
    }

    _scrollToCard(new_active_card) {
        let active_card_number = new_active_card.attr('data-id');
        let active_card_left = new_active_card.position().left + new_active_card.width();
        let active_card_right = new_active_card.position().left;
        let swipe_container_width = this.swipe_cards_container.width();

        if ((active_card_left > swipe_container_width && active_card_left > 0) || active_card_right < 0) {
            this.swipe_cards_container.animate({
                scrollLeft: 282 * active_card_number - 282
            }, 450);
        }
    }

    _changeCard(id) {
        if (id != this.state.current_card) {
            this.swipe_cards.removeClass('slider__item--active');
            const new_active_card = this.swipe_cards_container.find('.js-slider-item[data-id="' + id + '"]');
            new_active_card.addClass('slider__item--active');
            this.state.current_card = parseInt(id);

            if (window.innerWidth >= 1200) {
                this._scrollToCard(new_active_card);
            }

        }
    }

    _swipeNextCard() {
        const next_card = this.state.current_card + 1;
        if ($('.js-slider-item[data-id="' + next_card + '"]')[0]) {
            this._changeSubmenu(next_card);
            this._changeCard(next_card);
        }
    }

    _swipePrevCard() {
        const prev_card = this.state.current_card - 1;
        if ($('.js-slider-item[data-id="' + prev_card + '"]')[0]) {
            this._changeSubmenu(prev_card);
            this._changeCard(prev_card);
        }
    }

    _scrollMenu() {
        const active = $('.submenu__li--active');
        const element_left = active.position().left - this.swipe_submenu.position().left + active.width() + 30;
        const element_right = active.position().left - this.swipe_submenu.position().left;
        if (this.swipe_submenu_width < element_left && element_left > 0) {
            this.swipe_submenu.animate({
                scrollLeft: active.offset().left
            }, 450);
        } else if (element_right < 0) {
            this.swipe_submenu.animate({
                scrollLeft: active.offset().left - 30
            }, 450);
        }
    }

    _changeCheckboxState() {
        const absence_active_day = $('.slider__item--active');
        const absence_day_state = absence_active_day.attr('data-meal-state');
        const absence__checkbox = $('.absence__checkbox');

        if (absence_day_state === '1') {
            absence__checkbox.prop('checked', true);
        } else {
            absence__checkbox.prop('checked', false);
        }
    }

    _changeAbsenceDay() {

        const absence = $('.js-absence');
        const absence_checkbox = absence.find('.absence__checkbox');
        const absence_label = absence.find('.absence__label');
        const absence_day = $('.slider__item--active').attr('data-date');
        const absence_day_label = $('.slider__item--active').attr('data-date-label');
        absence_label.text(absence_day_label);
        absence_checkbox.attr('data-checkbox-date', absence_day);
        this._changeCheckboxState();
    }

    _events() {
        this.submenu_elements.on('click', (e) => {
            e.preventDefault();
            const submenu_element = $(e.currentTarget);
            const submenu_element_id = submenu_element.attr('data-id');

            this._changeSubmenu(submenu_element_id);
            this._changeCard(submenu_element_id);
            this._changeAbsenceDay();

        });

        this.swipe_cards_container.on('swipeleft', () => {
            this._swipeNextCard();
            this._changeAbsenceDay();
        });

        this.swipe_cards_container.on('swiperight', () => {
            this._swipePrevCard();
            this._changeAbsenceDay();
        });

        this.swipe_arrow_prev.on('click', () => {
            this._swipePrevCard();
            this._changeAbsenceDay();
        });

        this.swipe_arrow_next.on('click', () => {
            this._swipeNextCard();
            this._changeAbsenceDay();
        });


    }

    init() {
        if (this.swipe_container[0]) {
            this._initHammer();
            this._getInitialCard();
            this._changeCheckboxState();
            this._events();
        }
    }
}

export default SwipeCards;
