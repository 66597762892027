class RegisterServiceWorker {

    constructor(register_service_worker, $) {
        this.register_service_worker = register_service_worker;
    }

    _register() {
        if ("serviceWorker" in navigator) {
            window.addEventListener("load", function () {
                navigator.serviceWorker.register("service-worker.js");
            });
        }
    }

    _events() {

    }

    init() {
        if (this.register_service_worker[0]) {
            this._register();
        }
    }
}

export default RegisterServiceWorker;