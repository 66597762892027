class toggleMenu {

    constructor(toggle_menu, $) {
        this.toggle_menu = toggle_menu;
        this.toggle_button = $('.js-toggle-button');
        this.toggle_sidebar = $('.js-sidebar');
        this.toggle_main = $('.js-main');
        this.toggle_top_bar = $('.js-top-bar');
        this.toggle_date = $('.js-date');
        this.toggle_logout = $('.js-logout');
        this.html = $('html');
        this.body = $('body');
        this.status = $('.js-status');
    }

    _toggleOpenClose() {

        this.toggle_sidebar.toggleClass('sidebar--active');
        this.toggle_top_bar.toggleClass('top-bar--active');
        this.toggle_main.toggleClass('main--inactive');
        this.toggle_button.toggleClass('top-bar__menu--active');
        this.toggle_date.toggleClass('date--active');
        this.toggle_logout.toggleClass('logout--active');
    }

    _pushStatus() {
        this.status.toggleClass('status--pushed');
    }

    _togglePageScrolling() {
        if (this.status.hasClass('status--active-full')) {
            this.body.addClass('no-scroll--mobile');
            this.html.addClass('no-scroll--mobile');
        } else {
            this.body.toggleClass('no-scroll--mobile');
            this.html.toggleClass('no-scroll--mobile');
        }
    }

    _events() {

        this.toggle_button.on('click', () => {
            this._toggleOpenClose();
            this._togglePageScrolling();
            this._pushStatus();
        });

    }

    init() {
        if (this.toggle_menu[0]) {

            this._events();

        }
    }
}

export default toggleMenu;
