class Activities {
    constructor(activities, $) {
        this.activities = activities;
        this.activities_container = $('.js-activities');
        this.activities_button = this.activities_container.find($('.activities__button'));
        this.activities_btn = this.activities_button.find($('.activity__btn-text'));
        this.activities_join_text = this.activities_container.find($('.activity__btn-join').first()).text();
        this.activities_joined_text = this.activities_container.find($('.activity__btn-joined').first()).text();
    }

    _toggleActivity(activity_id, element, status) {

        const request = $.ajax({
            url: "api/join-activity",
            method: "POST",
            data: {
                activity_id: activity_id,
                status: status
            },
            dataType: "json"
        });

        request.done((response) => {
            if (response.success) {
                element.parent().prev('.activity__availability').find('.activity__number').text(response.available);
                this._toggleActivityClass(element);
            } else {
                this._showAvailabilityTooltip(element);
                //element.parent().prev('.activity__availability').find('.activity__number').text(response.available);

            }
        });


    }

    _changeAvailabilityNumber(elem) {
        elem.closest('.activity__availability');
    }

    _toggleActiveClass(element) {
        element.toggleClass('activity__btn--active');
    }

    _addActiveClass(element) {
        element.addClass('activity__btn--active');
    }

    _changeTextToJoined(element) {
        element.find(this.activities_btn).text(this.activities_joined_text);
    }

    _changeTextToJoin(element) {
        element.find(this.activities_btn).text(this.activities_join_text);
    }

    _checkIfJoined() {
        this.activities_button.each((i, el) => {
            let $this = $(el);
            if ($this.hasClass('activity__btn--active')) {
                this._changeTextToJoined($this);
            }
        });
    }

    _ifNotAvailable(element) {
        if (element.hasClass('activity__btn-full')) {
            //return false;
        }
    }
    _showAvailabilityTooltip(element) {
        const tooltip_item = element.find('.tooltip__elem');
        tooltip_item.addClass('tooltip__elem--show');
        setTimeout(function () {
            tooltip_item.removeClass('tooltip__elem--show');
        }, 4000);
    }
    _toggleActivityClass(element) {
        console.log(element);
        if (element.hasClass('activity__btn--active')) {
            this._changeTextToJoin(element);
        } else {
            this._changeTextToJoined(element);
        }
        this._toggleActiveClass(element);
    }

    _events() {

        this.activities_button.on('click', (e) => {
            let activities_item = $(e.currentTarget);
            const activities_id = activities_item.attr('data-id');
            let activities_status = 1;
            if (activities_item.hasClass('activity__btn--active')) {
                activities_status = 0;
            }

            this._toggleActivity(activities_id, activities_item, activities_status);
        });


    }

    init() {
        if (this.activities[0]) {
            this._events();
            this._checkIfJoined();
        }
    }
}

export default Activities;