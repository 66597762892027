class SplashScreen {

    constructor(splash_screen_container, $) {
        this.splash_screen_container = splash_screen_container;
        this.body = $('body');
        this.splash_screen = $('.js-splash-screen');
        this.splash_loader = this.splash_screen.find($('.splash__loader'));
        this.content = $('.js-content');
    }

    _loadBar() {
        this.splash_loader.addClass('splash-loader--full');
    }

    _hideSplash() {
        this.splash_screen.addClass('splash-screen--hidden');
    }

    _changeZIndexSplash() {
        this.splash_screen.addClass('splash-screen--hidden-z-index');
    }

    _showContent() {
        this.content.removeClass('content--hidden');
    }

    _events() {

    }

    init() {
        if (this.splash_screen_container[0]) {
            this._loadBar();
            setTimeout( ()=> {
                this._hideSplash();
                this._showContent();
                this._changeZIndexSplash();
            },500);

        }
    }
}


export default SplashScreen;