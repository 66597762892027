class FoodMenu {
    constructor(food_menu, $) {
        this.food_menu = food_menu;
        this.food_menu_container = $('.js-food-menu');
        this.food_menu_option = this.food_menu_container.find($('.food-menu__option'));
        this.food_menu_option_warning = this.food_menu_container.find($('.food-menu__option-warning'));
        this.food_menu_checkbox = this.food_menu_container.find($('.food-menu__checkbox'));
        this.food_menu_textarea = this.food_menu_container.find($('.food-menu__textarea'));
        this.food_menu_submit = this.food_menu_container.find($('.food-menu__submit'));
        this.food_menu_form = this.food_menu_container.find($('.food-menu__form'));
        this.food_menu_message_warning_success = this.food_menu_container.find($('.food-menu__warning--success'));
        this.food_menu_message_warning_failed = this.food_menu_container.find($('.food-menu__warning--failed'));
        this.active_day_state = $('.slider__item--active').attr('data-meal-state');
    }

    _activateOptionStar(dinner_id, element, status) {

        const request = $.ajax({
            url: "/api/star",
            method: "POST",
            data: {
                dinner_id: dinner_id,
                status: status
            },
            dataType: "json"
        });

        request.done((response) => {
            if (response.success) {

                if (response.status) {
                    this._activeStar(element);
                } else {
                    this._deactiveStar(element);
                }
            }
        });

        request.fail(() => {
            this._failedActivateStar();
        });

    }

    _activeStar(element) {
        element.addClass('options__icon--active');
    }

    _deactiveStar(element) {
        element.removeClass('options__icon--active');
    }

    _toggleStar(element) {
        element.toggleClass('options__icon--active');
    }

    _failedActivateStar() {
        this.food_menu_option_warning.removeClass('warning--hidden');
    }

    _activateCheckbox(element, date, state) {

        const request = $.ajax({
            url: "/api/meal-absence",
            method: "POST",
            data: {
                date: date,
                absence: state
            },
            dataType: "json"
        });

        request.done((response) => {
            if (response.success) {

                if (response.status) {
                    this._changeAbsenceDayState(state);
                }
            }

        });
    }

    _sendMessage(message) {

        const request = $.ajax({
            url: "/api/kitchen-message",
            method: "POST",
            data: {content: message},
            dataType: "json"
        });

        request.done((response) => {
            if (response.success) {
                this._succeedSendMessage();
                this.food_menu_form.addClass('food-menu__form--hidden');
            }
        });

        request.fail((response) => {
            this.food_menu_message_warning_failed.text(response.responseJSON.errors.content["0"]);
            this._failedSendMessage();
        });

    }

    _succeedSendMessage() {
        this.food_menu_message_warning_success.removeClass('warning--hidden');
        this.food_menu_message_warning_failed.addClass('warning--hidden');
    }

    _failedSendMessage() {
        this.food_menu_message_warning_failed.removeClass('warning--hidden');
        this.food_menu_message_warning_success.addClass('warning--hidden');
    }

    _changeAbsenceDayState(checkbox_state) {
        const absence_active_day = $('.slider__item--active');
        let state;
        if (checkbox_state) {
            state = '1';
        } else {
            state = '0';
        }
        absence_active_day.attr('data-meal-state', state);
    }

    _events() {

        this.food_menu_option.on('click', (e) => {
            let option_item = $(e.currentTarget);
            const option_id = option_item.attr('data-id');
            this._toggleStar(option_item);
            let option_status = option_item.hasClass('options__icon--active');
            this._activateOptionStar(option_id, option_item, option_status);

        });

        this.food_menu_checkbox.on('change', (e) => {
            let food_menu_checkbox_item = $(e.currentTarget);
            const food_menu_checkbox_date = food_menu_checkbox_item.attr('data-checkbox-date');
            let food_menu_checkbox_state = food_menu_checkbox_item.is(':checked');

            this._changeAbsenceDayState(food_menu_checkbox_state);

            this._activateCheckbox(food_menu_checkbox_item, food_menu_checkbox_date, food_menu_checkbox_state);
        });

        this.food_menu_submit.on('click', () => {
            let message = this.food_menu_textarea.val();
            this._sendMessage(message);
            return false;
        });


    }

    init() {
        if (this.food_menu[0]) {
            this._events();
        }
    }
}

export default FoodMenu;