class Warning {

    constructor(warning, $) {
        this.warning = warning;
        this.warning_container_storage = $('.js-warning-container-storage');
        this.warning_close = this.warning_container_storage.find($('.warning__close'));
        this.warning_item = this.warning_container_storage.find($('.warning'));
    }

    _setCookie(cookie_name) {
        $.cookie(cookie_name, cookie_name , { expires: 7 });
    }

    _hideWindow(element) {
        element.addClass('warning--hidden');
    }

    _checkStorage() {
        this.warning_item.each( function() {

            const warning_item = $(this);
            const warning_data = warning_item.attr('data-cookie');

            if (localStorage.getItem(warning_data) === null) {
                warning_item.removeClass('warning--hidden');
            }
        });
    }

    _setStorage(key, value) {
        localStorage.setItem(key, value);
    }

    _events() {
        this.warning_close.on('click', (e) => {
            const cookie_elem = $(e.currentTarget).closest('.warning');
            const cookie_name = cookie_elem.attr('data-cookie');

            this._setStorage(cookie_name, cookie_name);
            this._hideWindow(cookie_elem);
            return false;

        });
    }

    init() {
        if (this.warning[0]) {
            this._events();
            this._checkStorage();
        }
    }
}


export default Warning;