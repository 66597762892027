class FormValidation {

    constructor(form_validation, $) {
        this.form_validation = form_validation;
        this.form = $('.js-form-validation');
        this.form_button = this.form.find($('.form-validation__button'));
        this.login_form = $('.login-form');
    }


    _events() {
        this.login_form.on('submit', ()=> {
            this.form_button.each( (e)=> {
                const current_input = $(e.currentTarget);
                if (current_input.val()) {
                    console.log('ok');
                    current_input.addClass('ok');
                } else {
                    console.log('bad');
                    current_input.addClass('btn--warning');
                    return false;
                }

            });

        });
    }

    init() {
        if (this.form_validation[0]) {
            //this._events();
        }
    }
}

export default FormValidation;