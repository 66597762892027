class OnloadAnimation {

    constructor(onload_animation, $) {
        this.onload_animation = onload_animation;
        this.onload_animation_element = $('.js-onload-animation');
    }

    _toggleOpacity() {
        this.onload_animation_element.addClass('boxed__window--visible');
    }

    _events() {
        this._toggleOpacity();
    }

    init() {
        if (this.onload_animation[0]) {
            this._events();
        }
    }
}

export default OnloadAnimation;