class LoginForm {
    constructor(login_form, $) {
        this.login_form = login_form;
        this.login_form_container = $('.js-login-form');
        this.login_form_submit = this.login_form_container.find($('.login-form__submit'));
        this.login_form_input = this.login_form_container.find($('.login-form__input'));
        this.login_form_email = this.login_form_container.find($('.login-form__email'));
        this.login_form_password = this.login_form_container.find($('.login-form__password'));
        this.login_form_warning = this.login_form_container.find($('.login-form__warning'));
        this.login_form_warning_text = this.login_form_container.find($('.login-form__warning-text'));
    }

    _validateEmpty() {
        if ( this.login_form_password.val().length == 0 ) {
            this.login_form_warning.removeClass('warning--hidden');
            //this.login_form_warning_text.append('<div>Complete email field</div>');
            return false;
        } else {
            if ( this.login_form_email.val().length == 0 ) {
                this.login_form_warning.removeClass('warning--hidden');
                //this.login_form_warning_text.append('<div>Complete password field</div>');
                return false;
            }
        }
    }

    _events() {
        this.login_form_submit.on('click', () => {
            this._validateEmpty();
        });
    }

    init() {
        if (this.login_form[0]) {
            this._events();
        }
    }
}

export default LoginForm;