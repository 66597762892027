class Status {

    constructor(change_status, $) {
        this.change_status = change_status;
        this.change_status_button = $('.js-change-status');
        this.toogle_visibility_top_bar = $('.js-top-bar');
        //this.toggle_visibility_main = $('.js-main');
        this.toggle_visibility_status_container = $('.js-status');

        this.status = $('.js-status');
        this.status_item = this.status.find($('.status__item'));
        this.status_warning = this.status.find($('.status__warning'));
        this.status_icon = $('.js-change-status');

        this.html = $('html');
        this.body = $('body');
        this.sidebar  = $('.js-sidebar');
        this.date = $('.js-date');
    }

    _toggleShowHideStatusMenu() {
        this.change_status_button.toggleClass('status--active');
        this.toogle_visibility_top_bar.toggleClass('top-bar__status--active-full');
        //this.toggle_visibility_main.toggleClass('main--inactive-full');
        this.toggle_visibility_status_container.toggleClass('status--active-full');
    }

    _hideStatusMenu() {
        this.change_status_button.removeClass('status--active');
        this.toogle_visibility_top_bar.removeClass('top-bar__status--active-full');
        this.toggle_visibility_status_container.removeClass('status--active-full');
        this._togglePageScrolling();
    }

    _togglePageScrolling() {
        if (this.sidebar.hasClass('sidebar--active')) {
            this.body.addClass('no-scroll--mobile');
            this.html.addClass('no-scroll--mobile');
        } else {
            this.body.toggleClass('no-scroll--mobile');
            this.html.toggleClass('no-scroll--mobile');
        }
    }

    _changeStatus(status_id,  element ) {

        const request = $.ajax({
            url: "/api/status",
            method: "POST",
            data: { status : status_id },
            dataType: "json"
        });

        request.done( (response) => {
            if (response.success) {
               this._succeedChangeStatus(element);
            }
        });

        request.fail( () => {
            this._failedChangeStatus();
        });
    }

    _succeedChangeStatus(element) {
        this.status_item.removeClass('status__item--active');
        element.addClass('status__item--active');
    }

    _failedChangeStatus() {
        this.status_warning.removeClass('warning--hidden');
    }

    _changeIcon(element) {
        const status_icon_img = element.attr('data-icon');
        this.status_icon.removeClass('icon--normal').removeClass('icon--sick').removeClass('icon--home').removeClass('icon--doctor').addClass(status_icon_img);
    }

    _events() {

        this.change_status_button.on('click', () => {
            this._toggleShowHideStatusMenu();
            this._togglePageScrolling();
        });

        this.status_item.on('click', (e) => {
            let status_item = $(e.currentTarget);
            const status_id = status_item.attr('data-status');

            this._changeStatus(status_id, status_item);
            this._changeIcon(status_item);

            setTimeout( ()=> {
                this._hideStatusMenu();
            }, 400);

            return false;
        });

    }

    init() {
        if (this.change_status[0]) {

            this._events();
        }
    }
}


export default Status;