class Messages {
    constructor(messages, $) {
        this.janitor_message = messages;
        this.messages = $('.js-messages');
        this.messages_item = this.messages.find($('.messages__item'));
        this.messages_item_icon = this.messages.find($('.messages__icon'));
        this.messages_warning_failed = this.messages.find($('.messages_warnings'));
    }

    _succeedArchivedMessage() {

    }

    _failedArchivedMessage() {
        this.messages_warning_failed.removeClass('warning--hidden');
    }
    _archiveMessage() {

    }

    _events() {
        this.messages_item_icon.on('click', (e) => {
            this.message_item = $(e.currentTarget).closest(this.messages_item);
            this.message_item_id = this.message_item.attr('data-id');
            this.message_item.addClass('message--hidden');
        });
    }

    init() {
        if (this.messages[0]) {
            this._events();
        }
    }
}

export default Messages;