class Upload {

    constructor(upload, $) {
        this.upload = upload;
        this.upload_container = $('.js-upload');
        this.upload_input = this.upload_container.find($('.upload__input'));
        this.upload_info = this.upload_container.find($('.upload__info'));
    }

    _events() {
        this.upload_input.on('change', ()=> {
            this.upload_info.removeClass('upload__info--hidden');
        });
    }

    init() {
        if (this.upload[0]) {
            this._events();
        }

    }
}

export default Upload;