class JanitorMessage {
    constructor(janitor_message, $) {
        this.janitor_message = janitor_message;
        this.janitor_message_container = $('.js-janitor-message');
        this.janitor_message_warning_success = this.janitor_message_container.find($('.janitor-message__warning--success'));
        this.janitor_message_warning_failed = this.janitor_message_container.find($('.janitor-message__warning--failed'));
        this.janitor_message_submit = this.janitor_message_container.find($('.janitor-message__submit'));
        this.janitor_message_textarea = this.janitor_message_container.find($('.janitor-message__textarea'));
        this.janitor_form = this.janitor_message_container.find($('.janitor__form'));
    }

    _sendMessage(message) {

        const request = $.ajax({
            url: "/api/janitor-message",
            method: "POST",
            data: {content: message},
            dataType: "json"
        });

        request.done((response) => {
            if (response.success) {
                this._succeedSendMessage();
            }
        });

        request.fail((response) => {
            this.janitor_message_warning_failed.text(response.responseJSON.errors.content["0"]);
            this.janitor_message_warning_failed.removeClass('warning--hidden');
            this.janitor_message_warning_success.addClass('warning--hidden');
        });
    }

    _succeedSendMessage() {
        this.janitor_message_warning_success.removeClass('warning--hidden');
        this.janitor_message_warning_failed.addClass('warning--hidden');
        this.janitor_form.addClass('janitor__form--hidden');
    }

    _validateEmpty() {
        if ( this.janitor_message_textarea.val().length == 0 ) {
            this.janitor_message_warning_failed.removeClass('warning--hidden');
            this.janitor_message_warning_failed.text('Please, complete message field');
            return false;
        } else {
        }
    }

    _events() {
        this.janitor_message_submit.on('click', () => {
            let message = this.janitor_message_textarea.val();
            this._sendMessage(message);
            return false;
        });
    }

    init() {
        if (this.janitor_message[0]) {
            this._events();
        }
    }
}

export default JanitorMessage;